<template>
  <div class="layout-wrapper layout-content-navbar h-100">
    <div :class="'layout-container' + (colapse ? ' layout-menu-fixed' : '')">
      <aside class="layout-menu menu-vertical menu bg-menu-theme text-center">
        <div class="app-brand w-100">
          <a href="/" class="app-brand-link">
            <span class="app-brand-logo">
              <img src="/img/logol.svg" height="30" alt="" />
            </span>
          </a>

          <a
            href="javascript:void(0);"
            @click="colapse = false"
            v-if="colapse"
            style="display: block !important"
            class="layout-menu-toggle menu-link text-large ms-auto d-block"
          >
            <i class="bx bx-chevron-left bx-sm align-middle"></i>
          </a>
          <!-- <span class="version">v{{ version }}</span> -->
        </div>
        <div
          class="menu-inner-shadow"
          style="display: block"
          v-if="colapse"
        ></div>
        <div class="menu-inner-shadow" v-else></div>

        <ul
          class="menu-inner py-1"
          style="
            max-height: calc(100vh - 75px);
            overflow-y: auto;
            overflow-x: hidden;
          "
        >
          <!-- Dashboard -->
          <li
            :class="
              (m.path ? 'menu-item' : 'menu-header') +
              (active(m.path) ? ' active' : '')
            "
            v-for="(m, i) in getMenu()"
            :key="i"
          >
            <a
              v-if="m.path"
              @click="
                $router.push(m.path);
                colapse = false;
              "
              class="menu-link"
              :style="m.path ? 'cursor: pointer' : ''"
            >
              <font-awesome-icon
                v-if="m.ficon"
                class="menu-icon tf-icons bx bx-sm"
                :icon="m.ficon"
              />
              <LogoIcon v-else-if="m.eicon" class="sidebar-logo-icon menu-icon "></LogoIcon>
              <i v-else :class="'menu-icon tf-icons bx bx-' + m.icon"></i>

              <div data-i18n="Analytics">{{ m.name }}</div>
            </a>
            <span class="menu-header-text" v-else>{{ m.name }}</span>
          </li>
          <li class="menu-item">
            <a
              class="menu-link"
              style="cursor: pointer"
              @click="
                logout();
                $router.push('/login');
              "
              ><i class="menu-icon tf-icons bx bx-power-off"></i>
              <div data-i18n="Analytics">Sair</div></a
            >
          </li>
        </ul>
      </aside>
      <div
        class="layout-overlay layout-menu-toggle d-block"
        v-if="colapse"
      ></div>
      <div class="layout-page app-scroll">
        <!-- Navbar -->
        <div class="full d-flex d-xl-none">
          <a
            class="nav-item nav-link p-0 me-xl-4 text-white"
            href="javascript:void(0)"
            @click="colapse = true"
          >
            <i class="bx bx-menu bx-sm"></i>
          </a>
        </div>
        
        <!-- <nav class="d-flex d-xl-none layout-navbar align-items-center full">
          <div
            class="
              layout-menu-toggle
              navbar-nav
              align-items-xl-center
              me-3 me-xl-0
              d-xl-none
            "
          >
            <a
              class="nav-item nav-link px-0 me-xl-4 text-white"
              href="javascript:void(0)"
              @click="colapse = true"
            >
              <i class="bx bx-menu bx-sm"></i>
            </a>
          </div>
          <div
            class="nav-item align-items-center text-center"
            style="width: calc(100% - 70px)"
          >
            <img src="/img/logo.svg" height="35" alt="" />
          </div>
          <span class="version">v{{ version }}</span>
        </nav> -->
        <div class="content-wrapper" v-if="!full">
          <!-- Content -->

          <div class="container-xxl flex-grow-1 container-p-y">
            <slot name="body"></slot>
          </div>
        </div>
        <div class="layout-full-body" v-else>
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoIcon from "../LogoIcon.vue";

export default {
  props: ["full"],
  components: {
    LogoIcon,
  },
  data() {
    return {
      menuItem: window.location.pathname,
      menu: [
        { name: "Mapa", level: 0 },
        { name: "Dispositivos", path: "/?map=estacao", eicon: true, level: 0 },
        { name: "Previsão", path: "/?map=clima", ficon: "fa fa-cloud-sun", level: 0 },

        // { name: "Desenvolvedor", level: 10 },
        // { name: "ChatGPT", path: "/chatgpt", icon: "cool", level: 10 },
        // { name: "Sistema", path: "/sistema", icon: "chip", level: 10 },
        // { name: "Threads", path: "/threads", icon: "pulse", level: 10 },
        // { name: "Terminal", path: "/terminal", icon: "terminal", level: 10 },
        // { name: "Logs", path: "/logs", icon: "hdd", level: 10 },
        // { name: "Documentação", path: "/device-api", icon: "file", level: 10 },
        { name: "Administração", level: 5 },
        { name: "Cadastros", path: "/administracao", icon: "file", level: 10 },
        // { name: "Configuração", path: "/configuracao", icon: "cog", level: 10 },
        { name: "QR Code", path: "/admin/QRCode", icon: "qr", level: 10 },
        // { name: "Suporte", path: "/suporte", icon: "help-circle", level: 5 },
        { name: "Cadastro", level: 0 },
        // { name: "Estações", path: "/estacoes", icon: "filter-alt", level: 0 },
        {
          name: "Propriedade",
          path: "/propriedade",
          icon: "map-pin",
          level: 0,
        },
        { 
          name: "Dispositivos",
          path: "/dispositivos",
          icon: "chip",
          level: 0,
       },
        { name: "Relatórios", level: 5 },
        {
          name: "Relatório",
          path: "/report",
          icon: "book-open",
          level: 0,
        },
        // { name: "Visita técnica", level: 0, tecnico_verificado: true },
        // {
        //   name: "Nova visita",
        //   path: "/vista",
        //   icon: "id-card",
        //   level: 0,
        //   tecnico_verificado: true,
        // },
        // {
        //   name: "Minhas visitas",
        //   path: "/visitas",
        //   icon: "trip",
        //   level: 0,
        //   tecnico_verificado: true,
        // },
        // {
        //   name: "Meus produtores",
        //   path: "/meus-produtores",
        //   icon: "group",
        //   level: 0,
        //   tecnico_verificado: true,
        // },
        // { name: "Api", level: 0, api: true },
        // {
        //   name: "Api token",
        //   path: "/api-token",
        //   icon: "key",
        //   level: 0,
        //   api: true,
        // },
        // {
        //   name: "Documentação",
        //   path: "/api",
        //   icon: "file",
        //   level: 0,
        //   api: true,
        // },

        // { name: "Suporte", level: 10 },
        // { name: "Ajuda", path: "/ajuda", icon: "help-circle", level: 10 },
        // {
        //   name: "Reportar erro",
        //   path: "/reportar-erro",
        //   icon: "bug",
        //   level: 0,
        // },
        { name: "Conta", level: 0 },
        //{ name: "Código QR", path: "/ajuda", icon: "qr-scan", level: 0 },

        { name: "Conta", path: "/conta", icon: "user", level: 0 },
      ],
      colapse: false,
    };
  },
  methods: {
    isTecnico(m, user) {
      if (m.tecnico_verificado) {
        return user.tecnico_verificado;
      }
      return true;
    },
    isApi(m, user) {
      if (m.api) {
        return user.api;
      }
      return true;
    },
    getMenu() {
      var menu = [];
      var user = this.getUser();
      for (const m of this.menu) {
        if (m.level != null) {
          if (
            this.userLevel(m.level) &&
            this.isTecnico(m, user) &&
            this.isApi(m, user)
          ) {
            menu.push(m);
          }
        } else {
          menu.push(m);
        }
      }
      return menu;
    },
    active(path) {
      const currentPath = this.$route.path;
      const currentQuery = this.$route.query;

      if (!path) return false;

      const [basePath, query] = path.split('?');
      
      if (currentPath !== basePath) return false;

      if (query) {
        const queryParams = new URLSearchParams(query);
        for (const [key, value] of queryParams) {
          if (currentQuery[key] !== value) {
            return false;
          }
        }
      }

      return true;
    },
    actionUpdateUser() {
      this.$forceUpdate();
    },
  },
  created() {
    this.addEvent("update_user", this.actionUpdateUser);
  },
  unmounted() {
    this.removeEvent("update_user", this.actionUpdateUser);
  },
};
</script>

<style>
.app-scroll {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.layout-menu-fixed .menu-vertical {
  animation: menu-expand ease-in-out 0.1s !important;
  transform: translate3d(0, 0, 0);
}
@keyframes menu-expand {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0%, 0%, 0%);
  }
}
.app-brand {
  background-color: #006035;
  margin: 0px !important;
  height: 75px !important;
  position: relative;
}
.full {
  margin: 0px !important;
  max-width: 100% !important;
  background-color: transparent !important;
  position: absolute;

  color: #cee3ce;
  z-index: 1000;
  top: 50px;
}
.full i {
  background-color: #006035 !important;
  border-radius: 0 25px 25px 0;
  padding: 10px;
  padding-left: 20px;
}
.menu-item.active {
  position: relative;
}
.version {
  color: #fff;
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 12px;
}
.layout-full-body {
  width: 100%;
  height: 100%;
}
.sidebar-logo-icon {
  width: 1.5rem !important;
  margin: 0px 8px 0px 0px !important;
  padding: 0px !important;
  display: flex;
}
@media (max-width: 1199.98px) {
  .app-brand {
    position: unset;
  }
  .layout-full-body {
    height: 100%;
  }
  .app-brand {
    padding-top: 70px !important;
    padding-bottom: 30px;
  }
  .container-p-y:not([class^="pt-"]):not([class*=" pt-"]) {
    margin-top: 75px !important;
  }
}
</style>