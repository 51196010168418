<template>
  <div>
    <div style="min-height: 400px; height: 400px">
      <Map :showlayers="false" ref="map">
        <template v-slot:body>
          <div style="pointer-events: all">
            <button
              class="btn btn-primary m-3"
              @click="openEdit"
              style="cursor: pointer"
            >
              Editar
            </button>
          </div>
        </template>
      </Map>
    </div>
    <div class="modal fade" tabindex="-1" ref="modal_edit">
      <div
        class="modal-dialog modal-dialog-centered modal-lg modal-local-edit"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white mb-2">Editar localização</h5>
            <button type="button" class="btn-close" @click="closeEdit"></button>
          </div>
          <div class="modal-body p-0">
            <div class="local-edit-map">
              <Map :showlayers="false" ref="map_modal" @click="clickMap">
                <template v-slot:body>
                  <div>
                    <div class="div-btn-map-top">
                      <!-- <button
                        :title="b.title"
                        v-for="(b, i) in getTypes()"
                        :key="i"
                        :class="'btn-mapa' + (b.value == type ? ' active' : '')"
                        @click="type = b.value"
                      >
                        <font-awesome-icon
                          class="icone-card-clima"
                          :icon="b.icon"
                        />
                      </button> -->
                    </div>
                  </div>
                </template>
              </Map>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              v-if="type == 'area'"
              @click="
                this.values.area.area = [];
                updateMap();
              "
            >
              Limpar
            </button>
            <button type="button" class="btn btn-primary" @click="save">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Map from "./mapa/Map.vue";
export default {
  props: ["local", "types", "value"],
  components: {
    Map,
  },
  data() {
    return {
      type: "ponto",
      values: {
        ponto: { latitude: null, longitude: null },
        area: { latitude: null, longitude: null, area: [] },
      },
      btntypes: [
        {
          title: "Ponto",
          icon: "fa fa-location-dot",
          value: "ponto",
        },
        {
          title: "Area",
          icon: "fa fa-map-location-dot",
          value: "area",
        },
        // {
        //   title: "Propriedade agricola",
        //   icon: "fa fa-house",
        //   value: "propriedadeagricola",
        // },
        // {
        //   title: "Talhão",
        //   icon: "fa fa-tractor",
        //   value: "talhao",
        // },
      ],
    };
  },
  methods: {
    save() {
      var value = null;
      switch (this.type) {
        case "ponto": {
          value = this.values.ponto;
          value.tipo = "PONTO";
          break;
        }
        case "area": {
          value = this.values.area;
          value.tipo = "AREA";
          if (value.area.length < 3) {
            value = null;
          }
          break;
        }
      }
      this.$emit("value", value);
      this.updateMapPrin();
      this.closeEdit();
    },
    getTypes() {
      if (this.types == null) {
        return this.btntypes;
      } else {
        var ty = [];
        for (const tp of this.types) {
          for (const t of this.btntypes) {
            if (tp == t.value) {
              ty.push(t);
            }
          }
        }
      }
    },
    clickMap(e) {
      if (e.latlng) {
        // this.$refs.map.addPoints([
        //   { latitude: e.latlng.lat, longitude: e.latlng.lng },
        // ]);
        switch (this.type) {
          case "ponto": {
            this.values.ponto.latitude = e.latlng.lat;
            this.values.ponto.longitude = e.latlng.lng;
            break;
          }
          case "area": {
            this.values.area.area.push({
              latitude: e.latlng.lat,
              longitude: e.latlng.lng,
            });
            var lat = 0;
            var lng = 0;
            for (const a of this.values.area.area) {
              lat += a.latitude;
              lng += a.longitude;
            }
            this.values.area.latitude = lat / this.values.area.area.length;
            this.values.area.longitude = lng / this.values.area.area.length;
            break;
          }
        }
        this.updateMap();
      }
    },
    updateMap() {
      var point = null;
      switch (this.type) {
        case "ponto": {
          point = this.values.ponto;
          break;
        }
        case "area": {
          point = this.values.area;
          break;
        }
      }

      this.$refs.map_modal.addPoints([point]);
      this.$refs.map_modal.move(
        point.latitude,
        point.longitude,
        this.$refs.map_modal.getZoom()
      );
    },
    updateMapPrin() {
      var point = null;
      switch (this.type) {
        case "ponto": {
          point = this.values.ponto;
          break;
        }
        case "area": {
          point = this.values.area;
          break;
        }
      }
      this.$refs.map.addPoints([point]);
      this.$refs.map.move(
        point.latitude,
        point.longitude,
        this.$refs.map.getZoom()
      );
    },
    openEdit() {
      $(this.$refs.modal_edit).modal("show");
      $(this.$refs.modal_edit).appendTo("body");

      this.$refs.map_modal.leaflet.removeLayer(this.$refs.map_modal.layers.outdoors);
      this.$refs.map_modal.leaflet.addLayer(this.$refs.map_modal.layers.satellite_street);
      
      setTimeout(() => {
        this.$refs.map_modal.mapUpdate();
      }, 100);
      this.updateMap();
    },
    closeEdit() {
      $(this.$refs.modal_edit).modal("hide");
    },
    add(point, markerGroup, map) {
      // debugger;
      if (point.area == null) {
        var icone = L.divIcon({
          className: "",
          iconSize: [10, 10],
          html: `<div class="pin-marker-edit"></div>`,
        });

        L.marker([point.latitude, point.longitude], {
          point: point,
          icon: icone,
        })
          .addTo(markerGroup)
          .on("click", () => {});
      } else {
        var locs = [];
        for (const a of point.area) {
          locs.push([a.latitude, a.longitude]);
        }
        L.polygon(locs, { color: "red" })
          .addTo(markerGroup)
          .on("click", () => {});
      }
    },
  },
  mounted() {
    this.$refs.map.leaflet.addLayer(this.$refs.map.layers.satellite_street);
    this.$refs.map.leaflet.removeLayer(this.$refs.map.layers.outdoors);
    
    this.$refs.map.event = {
      add: this.add,
    };
    this.$refs.map_modal.event = {
      add: this.add,
    };
    if (this.value) {
      this.values.ponto.latitude = this.value.latitude;
      this.values.ponto.longitude = this.value.longitude;
      this.values.area.latitude = this.value.latitude;
      this.values.area.longitude = this.value.longitude;

      if (this.value.area) {
        this.values.area.area = this.value.area;
        this.type = "area";
      }
      setTimeout(() => {
        this.updateMapPrin();
      }, 150);
    }
  },
  watch: {
    type(t) {
      this.updateMap();
    },
  },
};
</script>

<style>
.local-edit-map {
  height: 60vh;
  min-height: 60vh;
}
.div-btn-map-top {
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 10px;
}

.pin-marker-edit {
  /* Set the marker size here */
  width: 30px;
  height: 30px;
  border-radius: 2rem;
  /* Set the marker color here */
  background: #006035;

  display: inline-block;
  border-bottom-right-radius: 0;
  position: absolute;
  transform: rotate(45deg);

  /* optional fanciness */
  border: 1px solid #006035;
  margin-top: -30px;
  margin-left: -9px;
}
/* inner circle (optional if you don't need the central dot) */
.pin-marker-edit::before {
  content: "";
  background: white;
  width: 50%;
  height: 50%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  /* optional fanciness */
  box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}
/* shadow (optional if you don't need a shadow) */
.pin-marker-edit::after {
  content: "";
  background: rgba(128, 128, 128, 0.2);
  width: 75%;
  height: 75%;
  border-radius: 100%;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
}

@media (max-width: 700px) {
  .modal-local-edit {
    margin: 3px;
    margin-top: 40px;
  }
  .local-edit-map {
    height: 75vh;
    min-height: 75vh;
  }
}
</style>
