<template>
  <div class="h-100 w-100" style="position: relative">
    <div :id="id" class="h-100 w-100"></div>
    <div class="map-body">
      <slot name="body"></slot>
    </div>
    <div v-html="css"></div>
  </div>
</template>

<script>
export default {
  props: ["showlayers"],
  data() {
    return {
      css: null,
      id: "map-" + this.$.uid,
      leaflet: null,
      markerGroup: null,
      layers: null,
      mapboxAttribution:
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      accessToken:
        "pk.eyJ1IjoiYWxsdmFwbWFwcyIsImEiOiJjbHZpamdhN2UxMjEwMmlvbzV4MGdobnE0In0.zRNgPezwcw1akRIScHgp8A",
      mapboxUrl: null,
      layers: null,
      componente: null,
      popup: null,
      event: {},
      points: [],
      max_points: 50,
    };
  },
  methods: {
    moveArea() {
      var info = this.getInfo();
    },
    addPoints(points) {
      this.points = points;
      this.leaflet.removeLayer(this.markerGroup);
      this.markerGroup = L.layerGroup().addTo(this.leaflet);
      for (const point of points) {
        if (this.event.add) {
          this.event.add(point, this.markerGroup, this);
        } 
      }
    },
    move(lat, lng, zoom = 12) {
      this.leaflet.setView([lat, lng], zoom);
    },
    getZoom() {
      return this.leaflet.getZoom();
    },
    getInfo() {
      var latLng = this.leaflet.getCenter();
      var zoom = this.leaflet.getZoom();
      var bounds = this.leaflet.getBounds();
      return {
        latitude: latLng.lat,
        longitude: latLng.lng,
        zoom: zoom,
        latitude_max: bounds._northEast.lat,
        longitude_max: bounds._northEast.lng,
        latitude_min: bounds._southWest.lat,
        longitude_min: bounds._southWest.lng,
      };
    },
    clickPonto(data) {
      this.$emit("clickponto", data);
    },
    mapUpdate() {
      setTimeout(() => {
        this.leaflet.invalidateSize();
      }, 100);
    },
  },
  mounted() {
    this.mapboxUrl =
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" +
      this.accessToken;
    this.layers = {
      streets: L.tileLayer(this.mapboxUrl, {
        id: "mapbox/streets-v11",
        attribution: this.mapboxAttribution,
      }),
      outdoors: L.tileLayer(this.mapboxUrl, {
        id: "mapbox/outdoors-v11",
        attribution: this.mapboxAttribution,
      }),
      light: L.tileLayer(this.mapboxUrl, {
        id: "mapbox/light-v10",
        attribution: this.mapboxAttribution,
      }),
      dark: L.tileLayer(this.mapboxUrl, {
        id: "mapbox/dark-v10",
        attribution: this.mapboxAttribution,
      }),
      satellite: L.tileLayer(this.mapboxUrl, {
        id: "mapbox/satellite-v9",
        attribution: this.mapboxAttribution,
      }),
      satellite_street: L.tileLayer(this.mapboxUrl, {
        id: "mapbox/satellite-streets-v11",
        attribution: this.mapboxAttribution,
      }),
    };
    var latLngBegin = [-29.452491, -51.959624];
    this.leaflet = L.map(this.id, {
      center: latLngBegin,
      zoom: 12,
      layers: [this.layers.outdoors],
      zoomControl: this.zoomshow == null ? true : this.zoomshow,
      layersControl: false,
    });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        latLngBegin = [position.coords.latitude, position.coords.longitude];
        this.leaflet.setView(latLngBegin, 12);
      });
    }
    var baseMaps = {
      Ruas: this.layers.streets,
      Elevação: this.layers.outdoors,
      Light: this.layers.light,
      Dark: this.layers.dark,
      Satélite: this.layers.satellite,
      Híbrido: this.layers.satellite_street,
    };
    this.leaflet.on("click", (e) => {
      this.$emit("click", e);
    });
    // this.leaflet.on("click", (e) => {
    //   return;
    //   if (this.addarea == false) {
    //     return;
    //   }
    //   var latlngs = this.componente.getLatLngs();
    //   latlngs[0].push([e.latlng.lat, e.latlng.lng]);
    //   this.componente.setLatLngs(latlngs);
    //   this.$emit("ponto", latlngs[0]);
    // });
    //  this.leaflet.on("moveend", this.buscarPorTipo);
    this.leaflet.on("moveend", (e) => {
      if (this.event.move) {
        this.event.move(this, this.getInfo(), e);
      }
    });
    if (this.showlayers != null && !this.showlayers) {
      this.css =
        "<style>.leaflet-touch .leaflet-control-layers-toggle {display: none}</style>";
    }
    L.control.layers(baseMaps).addTo(this.leaflet);
    this.markerGroup = L.layerGroup().addTo(this.leaflet);
    this.mapUpdate();
    this.componente = L.polygon([], {
      color: "red",
    }).addTo(this.leaflet);
    // zoom the map to the polygon
    this.leaflet.addLayer(this.componente);
  },
  beforeUnmount() {
    this.leaflet.remove();
  },
};
</script>

<style>
.square-pin-icon {
  display: inline-block;
  position: absolute;
  top: -29px;
  left: -13px;
}
.square-pin-icon-container {
  display: flex;
  width: 36px;
  height: 27px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #ffffff;
  box-shadow: 0px 15px 20px -3px #000000;
}
.square-pin-icon-text-container {
  margin: auto;
  text-align: center;
  display: flex;
  width: 30px;
  height: 20px;
  position: relative;
  font-size: 12px;
  border-radius: 3px;
}
.square-pin-icon-text {
  margin: auto;
  color: #ffffff;
  font-weight: 900;
  font-family: "Arial";
}
.square-pin-icon-point {
  position: absolute;
  top: 27px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin: auto;
  border-top: 10px solid #ffffff;
  left: 13px;
}
.map-area {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 900;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.map-body {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.leaflet-control-zoom {
  display: none !important;
}
</style>